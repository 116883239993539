import React, { FormEvent, memo, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';

type Props = {
    pageSize: number;
    setPage: (page: number) => void;
    setPageSize: (pageSize: number) => void;
    onSubmitSearch: (citizenId: string) => void;
};

function AuthenticationHistorySearchBar(props: Props) {
    const { pageSize, setPage, setPageSize, onSubmitSearch } = props;

    const citizenIdInputRef = useRef<HTMLInputElement>(null);

    const handleSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const citizenIdInput = citizenIdInputRef.current;
        if (!citizenIdInput) return;

        setPage(1);
        onSubmitSearch(citizenIdInput.value.trim());
    };

    return (
        <form
            onSubmit={handleSubmitSearch}
            className="flex flex-row justify-end gap-4"
        >
            <Form.Group className="m-0">
                <Form.Label>Page Size</Form.Label>
                <Form.Control
                    as="select"
                    defaultValue={pageSize}
                    onChange={event => setPageSize(Number(event.target.value))}
                >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </Form.Control>
            </Form.Group>
            <Form.Group className="m-0">
                <Form.Label>Citizen ID</Form.Label>
                <Form.Control
                    ref={citizenIdInputRef}
                    placeholder="Citizen ID"
                    className="w-[200px]"
                />
            </Form.Group>
            <Button
                type="submit"
                className="self-end"
            >
                Search
            </Button>
        </form>
    );
}

export default memo(AuthenticationHistorySearchBar);
