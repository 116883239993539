import React, { useEffect, useState } from 'react';
import AuthenticationHistorySearchBar from './AuthenticationHistorySearchBar';
import { Badge, Table } from 'react-bootstrap';
import InfinitePagination from 'common/components/InfinitePagination';
import axios, { AxiosError } from 'axios';
import { ResponseList } from 'models/Response';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

export interface AuthenticationHistory {
    citizen_id: string;
    action_id: string;
    action: 'login';
    action_response: 'success' | 'fail';
    action_message?: string;
    timestamp: string;
    ip_address: string;
    client_ip_address: string;
    key_uuid: string;
    mode: string;
}

function AuthenticationHistoryLogs() {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [searchByCitizenId, setSearchByCitizenId] = useState<string>();

    const { error, data, isPlaceholderData } = useQuery({
        queryKey: ['auth-history', { page, pageSize, searchByCitizenId }],
        queryFn: () =>
            axios.get<ResponseList<AuthenticationHistory>>('/v1/auth-history', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'WF_APPLICATION'
                    )}`
                },
                params: {
                    page,
                    page_size: pageSize,
                    citizen_id: searchByCitizenId
                }
            }),
        select: response => response.data.result_list,
        keepPreviousData: true
    });

    // Error handling
    useEffect(() => {
        if (!error) return;

        if (error instanceof AxiosError) {
            Swal.fire('Error', error.response?.data.message || 'ไม่ทราบสาเหตุ');
        } else if (error instanceof Error) {
            Swal.fire('Error', error.message);
        }
    }, [error]);

    const paginationJSX = (
        <InfinitePagination
            disabledNext={!data || data.length === 0}
            page={page}
            style={{
                padding: '0px',
                marginTop: '16px',
                justifyContent: 'flex-end'
            }}
            onChangePage={(_event, page) => setPage(page)}
        />
    );

    return (
        <div>
            <AuthenticationHistorySearchBar
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                onSubmitSearch={setSearchByCitizenId}
            />

            {paginationJSX}

            <Table
                size="sm"
                className="table-bordered table-responsive-sm table-hover table-sm mt-4 table align-middle"
            >
                <thead>
                    <tr>
                        <th>Mode</th>
                        <th>User</th>
                        <th>Action</th>
                        <th>Action Result</th>
                        <th>Action Message</th>
                        <th>Timestamp</th>
                        <th>IP Address</th>
                        <th>Client IP Address</th>
                        <th>Key UUID</th>
                    </tr>
                </thead>
                <tbody>
                    {!data || data?.length === 0 ? (
                        <tr className="text-center">
                            <td colSpan={9}>No data</td>
                        </tr>
                    ) : (
                        data.map(log => {
                            const formattedDate = dayjs(log.timestamp).format(
                                'DD/MM/YYYY HH:mm:ss น.'
                            );

                            return (
                                <tr key={log.action_id}>
                                    <td>
                                        <Badge
                                            variant={
                                                log.mode === 'gui'
                                                    ? 'primary'
                                                    : 'info'
                                            }
                                        >
                                            <span className="relative top-[1px]">
                                                {log.mode.toUpperCase()}
                                            </span>
                                        </Badge>
                                    </td>
                                    <td>{log.citizen_id}</td>
                                    <td>{log.action}</td>
                                    <td>
                                        <Badge
                                            variant={
                                                log.action_response ===
                                                'success'
                                                    ? 'success'
                                                    : 'danger'
                                            }
                                        >
                                            {log.action_response}
                                        </Badge>
                                    </td>
                                    <td>{log.action_message}</td>
                                    <td>{formattedDate}</td>
                                    <td>{log.ip_address}</td>
                                    <td>{log.client_ip_address}</td>
                                    <td>{log.key_uuid}</td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>

            {paginationJSX}
        </div>
    );
}

export default AuthenticationHistoryLogs;
